














































































import Vue from "vue";
import { mapState } from "vuex";
import AddAdminDialog from "@/components/AddAdminDialog.vue";
import Request from "@/modules/request";

export default Vue.extend({
  computed: {
    ...mapState(["admin"]),
  },
  components: {
    AddAdminDialog,
  },
  data: () => ({
    showAddAdminDialog: false,
    showConfirmDialog: false,
    adminToDelete: 0,
    loadingDelete: false,
  }),
  beforeMount() {
    if (!this.$store.state.admins.length) {
      this.$store.dispatch("getAdmins");
    }
  },
  methods: {
    showConfirmation(id: number) {
      this.adminToDelete = id;
      this.showConfirmDialog = true;
    },
    hideConfirmation() {
      this.adminToDelete = 0;
    },
    async confirmDeleteAdmin() {
      this.loadingDelete = true;
      try {
        await Request.shared.delete(`admin/${this.adminToDelete}`);
        this.$store.dispatch("getAdmins");
      } catch (error) {
        console.error(error);
      }
      this.loadingDelete = false;
      this.showConfirmDialog = false;
    },
  },
  watch: {
    showConfirmDialog() {
      if (!this.showConfirmDialog) {
        this.hideConfirmation();
      }
    },
  },
});
